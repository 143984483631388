var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ListIcon",
      "size": "30"
    }
  }), _vm._v(" Avaliar Desafios ")], 1), _c('h4', {
    staticClass: "mt-1"
  }, [_vm._v("Selecione um desafio para avaliar:")])])], 1)], 1)], 1)], 1), _c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Pesquisar"
    }
  })], 1), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    staticStyle: {
      "min-height": "50vw"
    },
    attrs: {
      "items": _vm.tableItems,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolverStatusVariant(data.item.approved))
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveUserStatusText(data.item.approved)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: _vm.obterUrlAvaliar(data.item.modelo),
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Avaliar")])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }